// ----------------------------------------------------------------------

export const _paymentMethods = [
  {
    value: 'RazorPay',
    label: 'RazorPay with Paypal',
    caption: 'You will be redirected to RazorPay website to complete your purchase securely.',
    icons: [
      'https://rzp-1415-prod-dashboard-activation.s3.amazonaws.com/org_100000razorpay/main_logo/phpAJgHea',
    ],
  },
  {
    value: 'credit_card',
    label: 'Credit / Debit Card',
    caption: 'We support Mastercard, Visa, Discover and Stripe.',
    icons: [
      'https://zone-assets-api.vercel.app/assets/icons/payment/ic_mastercard.svg',
      'https://zone-assets-api.vercel.app/assets/icons/payment/ic_visa.svg',
    ],
  },
];
