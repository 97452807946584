
export const ProjectStatus = {
	all: 'all',
    applied: 'applied',
	rejected: 'rejected',
    todo: 'todo',
	inProgress: 'inProgress',
    paused: 'paused',
	completed: 'completed',
    c_cancelled: 'client_cancelled',
    f_cancelled: 'freelancer_cancelled',
}